<template>
  <div class="school">
            <div class="top-box">
              <div class="top-title">院校信息查询</div>
              <div class="btn-box">
                <div class="btn" @click="handleSearchClick()"><i class="el-icon-search"></i> 查询</div>
                <div class="btn" @click="handlePushClick()"><i class="el-icon-plus"></i> 推送</div>
              </div>
            </div>
            <el-row class="form-group">
              <el-col :span="6" class="form-item">
                  <div class="label">院校名称</div>
                  <div class="input-box">
                    <el-input v-model="name" placeholder="请输入院校名称" class="form-control"></el-input>
                  </div>
              </el-col>
              <el-col :span="6" class="form-item">
                  <div class="label">院校特色</div>
                  <div class="input-box">
                    <el-select class="form-control" v-model="feature" placeholder="请选择"
                    v-loading="featureListLoading"
                    element-loading-spinner="el-icon-loading">
                      <el-option key="0" label="请选择..." value=""></el-option>
                      <el-option
                        v-for="item in featureList"
                        :key="item.key"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </div>
              </el-col>
              <el-col :span="6" class="form-item">
                  <div class="label">院校省份</div>
                  <div class="input-box">
                    <el-select class="form-control" v-model="province" placeholder="请选择"
                    v-loading="provinceListLoading"
                    element-loading-spinner="el-icon-loading">
                      <el-option key="0" label="请选择..." value=""></el-option>
                      <el-option
                        v-for="item in provinceList"
                        :key="item.key"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </div>
              </el-col>
              <el-col :span="6" class="form-item">
                  <div class="label">院校城市</div>
                  <div class="input-box">
                    <el-select class="form-control" v-model="city" placeholder="请选择"
                    v-loading="cityListLoading"
                    element-loading-spinner="el-icon-loading">
                      <el-option key="0" label="请选择..." value=""></el-option>
                      <el-option
                        v-for="item in cityList"
                        :key="item.key"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </div>
              </el-col>
              <el-col :span="6" class="form-item">
                  <div class="label">院校类别</div>
                  <div class="input-box">
                    <el-select class="form-control" v-model="type" placeholder="请选择"
                    v-loading="typeListLoading"
                    element-loading-spinner="el-icon-loading">
                      <el-option key="0" label="请选择..." value=""></el-option>
                      <el-option
                        v-for="item in typeList"
                        :key="item.key"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </div>
              </el-col>
              <el-col :span="6" class="form-item">
                  <div class="label">院校层次</div>
                  <div class="input-box">
                    <el-select class="form-control" v-model="level" placeholder="请选择"
                    v-loading="levelListLoading"
                    element-loading-spinner="el-icon-loading">
                      <el-option key="0" label="请选择..." value=""></el-option>
                      <el-option
                        v-for="item in levelList"
                        :key="item.key"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </div>
              </el-col>
              <el-col :span="6" class="form-item">
                  <div class="label">院校性质</div>
                  <div class="input-box">
                    <el-select class="form-control" v-model="nature" placeholder="请选择"
                    v-loading="natureListLoading"
                    element-loading-spinner="el-icon-loading">
                      <el-option key="0" label="请选择..." value=""></el-option>
                      <el-option
                        v-for="item in natureList"
                        :key="item.key"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </div>
              </el-col>
            </el-row>
            <!--  -->
            <div class="table-container-body">
              <el-table :data="schoolTableData" height="500" stripe class="table-body" ref="schoolTable"
              :header-cell-style="{background:'#e6e6e6', color: '#646464'}"
              v-loading="schoolTableLoading">
                <el-table-column prop="id" fixed type="selection" width="42"></el-table-column>
                <el-table-column prop="logo" label="院校logo" width="80">
                   <template slot-scope="scope">
                     <img class="logo" :src="scope.row.logo"/>
                   </template>
                </el-table-column>
                <el-table-column prop="name" label="院校名称"></el-table-column>
                <el-table-column prop="feature" label="院校特色" width="240">
                  <template slot-scope="scope">
                    <div class="label" v-for="label in scope.row.feature"
                      :key="label.title"
                      :style="{'color':label.color, 'border-color':label.color}">
                      {{label.title}}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="createDate" label="办学时间" width="80"></el-table-column>
                <el-table-column prop="type" label="院校类别"></el-table-column>
                <el-table-column prop="level" label="院校层次"></el-table-column>
                <el-table-column prop="nature" label="院校性质"></el-table-column>
                <el-table-column prop="department" label="隶属部门"></el-table-column>
                <el-table-column prop="province" label="省份名称"></el-table-column>
                <el-table-column prop="city" label="城市名称"></el-table-column>
              </el-table>
            </div>
            <div class="table-container-footer">
              <el-pagination
                layout="total, prev, pager, next, sizes, jumper"
                :page-sizes="[10, 50, 100, 500]"
                :page-size="schoolTablePageSize"
                :total="schoolTableTotal"
                :current-page="schoolTablePageNo"
                @current-change="schoolPageChange"
                @size-change="schoolSizeChange"
              >
              </el-pagination>
            </div>
            <el-dialog title="选择客户" :visible.sync="dialogVisible">
              <div class="school-dialog-body">
                 <el-row class="form-group">
                    <el-col :span="8" class="form-item">
                        <div class="label">学生姓名</div>
                        <div class="input-box">
                          <el-input v-model="studentName" placeholder="请输入姓名" class="form-control"></el-input>
                        </div>
                    </el-col>
                    <el-col :span="2" class="form-item">
                        <div class="search-btn" @click="handleStudentSearchClick()">查询</div>
                    </el-col>
                 </el-row>
                 <div class="table-container-body">
                    <el-table :data="studentTableData" height="500" stripe class="table-body"
                    :header-cell-style="{background:'#e6e6e6', color: '#646464'}"
                    v-loading="studentTableLoading">
                      <el-table-column fixed type="selection" prop="studentId" width="42">
                      </el-table-column>
                      <el-table-column prop="studentName" label="学生姓名">
                      </el-table-column>
                      <el-table-column prop="gender" label="性别">
                      </el-table-column>
                      <el-table-column prop="level" label="学届">
                      </el-table-column>
                    </el-table>
                  </div>
                  <div class="table-container-footer">
                    <el-pagination
                      layout="total, prev, pager, next, sizes, jumper"
                      :page-sizes="[10, 50, 100, 500]"
                      :page-size="studentPageSize"
                      :total="studentTableTotal"
                      :current-page="studentPageNo"
                      @current-change="studentPageChange"
                      @size-change="studentSizeChange"
                    >
                    </el-pagination>
                  </div>
              </div>
              <span slot="footer" class="dialog-footer">
                <div class="confirm-btn" @click="dialogVisible = false">确  认</div>
              </span>
            </el-dialog>
            <!--  -->
          </div>
</template>
<script>
export default {
  name: "school",
  components: {},
  props: {
    msg: String,
  },
  data() {
    return {
      schoolTablePageNo: 1,
      schoolTablePageSize:10,
      schoolTableTotal:0,
      studentPageNo: 1,
      studentPageSize:10,
      studentTableTotal:0,
      dialogVisible:false,
      featureListLoading:false,
      provinceListLoading:false,
      cityListLoading:false,
      typeListLoading:false,
      levelListLoading:false,
      natureListLoading:false,
      schoolTableLoading:false,
      studentTableLoading:false,
      name:"",
      feature:"",
      type:"",
      level:"",
      nature:"",
      province:"",
      city:"",
      studentName:"",
      featureList:[],
      provinceList:[],
      cityList:[],
      typeList:[],
      levelList:[],
      natureList:[],
      schoolTableData: [],
      studentTableData:[]
    };
  },
  methods: {
    initSelectData(){
      this.featureListLoading = true;
      //获取院校特色
      this.$http.post(this.$apis.getDictionary+"?type=label").then((res) => {
        this.featureListLoading = false;
        if (res.data) {
          this.$store.commit("setFeatureList", res.data);
          this.featureList = res.data.map(r=>{
            return {
              key:r.id,
              label:r.name,
              value:r.code
            }
          });
        }
      });
      this.provinceListLoading = true;
      //获取省份
      this.$http.post(this.$apis.getProvinceOrCityList+"?type=province").then((res) => {
        this.provinceListLoading = false;
        if (res.data) {
          this.$store.commit("setProvinceList", res.data);
          this.provinceList = res.data.map(r=>{
            return {
              key:r.id,
              label:r.name,
              value:r.id
            }
          });
        }
      });
      this.cityListLoading = true;
      //获取城市
      this.$http.post(this.$apis.getProvinceOrCityList+"?type=city").then((res) => {
        this.cityListLoading = false;
        if (res.data) {
          this.$store.commit("setCityList", res.data);
          this.cityList = res.data.map(r=>{
            return {
              key:r.id,
              label:r.name,
              value:r.id
            }
          });
        }
      });
      this.typeListLoading = true;
      //获取院校类别
      this.$http.post(this.$apis.getDictionary+"?type=school_type").then((res) => {
        this.typeListLoading = false;
        if (res.data) {
          this.$store.commit("setSchoolTypeList", res.data);
          this.typeList = res.data.map(r=>{
            return {
              key:r.id,
              label:r.name,
              value:r.code
            }
          });
        }
      });
      this.levelListLoading = true;
      //获取院校层次
      this.$http.post(this.$apis.getDictionary+"?type=school_level").then((res) => {
        this.levelListLoading = false;
        if (res.data) {
          this.$store.commit("setSchoolLevelList", res.data);
          this.levelList = res.data.map(r=>{
            return {
              key:r.id,
              label:r.name,
              value:r.code
            }
          });
        }
      });

      //获取院校性质
      this.natureListLoading = true;
      this.$http.post(this.$apis.getDictionary+"?type=school_nature").then((res) => {
        this.natureListLoading = false;
        if (res.data) {
          this.$store.commit("setSchoolNatureList", res.data);
          this.natureList = res.data.map(r=>{
            return {
              key:r.id,
              label:r.name,
              value:r.code
            }
          });
        }
      });
    },
    initSchoolTable(){
      this.schoolTableLoading = true;
      var data={
        city: this.city,
        currentPage: this.schoolTablePageNo,
        dept: "",
        labels: [
          {
            lcode: this.feature,
          }
        ],
        level: this.level,
        nature: this.nature,
        pageSize: this.schoolTablePageSize,
        province: this.province,
        schoolName: this.name,
        schoolRunningTime: "",
        sprovinceRanking: "",
        stotalRanking: "",
        type: this.type
      }
      //获取分页表格数据
      this.$http.post(this.$apis.getSchoolByPageCondition,data).then((res) => {
        this.schoolTableLoading = false;
        if (res.data) {
          this.schoolTableData = res.data.list.map(r=>{
            return {
              id:r.id,
              logo: r.pic,
              name: r.schoolName,
              feature: r.labels.map(rm=>{ return { title:rm.lName, color:rm.lColor} }),
              createDate:r.schoolRunningTime,
              type:r.type,
              level:r.level,
              nature:r.nature,
              department:r.dept,
              province:r.province,
              city:r.city,
            }
          });
          this.schoolTableTotal = res.data.total;

        }
      });
    },
    initStudentTable(){
      this.studentTableLoading = true;
      var data={
          notMapping: "",
          phone: "",
          province: "",
          serviceId: "",
          studentName: this.studentName
      }
      //获取分页表格数据
      this.$http.post(this.$apis.getStuderntByPage+"?page="+this.studentPageNo+"&size="+this.studentPageSize,data).then((res) => {
        this.studentTableLoading = false;
        if (res.data) {
          this.studentTableData = res.data.list.map(r=>{
            return {
              studentId:r.studentId,
              studentName: r.studentName,
              //todo
              gender:"男",// r.gender,
              level:"2021届", //r.level,
            }
          });
          this.schoolTableTotal = res.data.total;

        }
      });
    },
    handleSearchClick(){
      this.schoolTablePageNo = 1;
      this.initSchoolTable();
    },
    handleStudentSearchClick(){
      this.studentPageNo = 1;
      this.initStudentTable();
    },
    handlePushClick() {
      let selectData = this.$refs.schoolTable.selection;
      if(selectData.length<=0){
        this.$message.warning("请先选择需要推送的数据");
          return;
      }
      this.dialogVisible = true;
      this.initStudentTable();
    },
    getRanbowColor(){
      let colors= ["#da564f","#fdda4c","#b2e175","#bf53db","f98ac4"];
      let ranbow = Math.floor(Math.random()*4);
      return colors[ranbow];
    },
    studentPageChange(val) {
      this.studentPageNo = val;
      this.initStudentTable();
    },
    studentSizeChange(val){
      this.studentPageSize = val;
      this.initStudentTable();
    },
    schoolPageChange(val) {
      this.schoolTablePageNo = val;
      this.initSchoolTable();
    },
    schoolSizeChange(val){
      this.schoolTablePageSize = val;
      this.initSchoolTable();
    }
  },
  mounted(){
    this.initSelectData();
    this.initSchoolTable();
  },
};
</script>
<style lang="less" scoped>
.school {
  margin: 20px;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  border-radius: 5px;
  border: 1px solid rgba(230, 230, 230, 100);

  .top-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 13px 10px;
    border-bottom: 1px solid rgba(230, 230, 230, 100);

    .top-title {
      color: rgba(16, 16, 16, 100);
      font-size: 20px;
      font-weight: 700;
      padding-left: 20px;
      position: relative;
    }
    .top-title::after {
      position: absolute;
      display: block;
      content: "";
      top: 50%;
      left: 5px;
      transform: translateY(-50%);
      height: 27px;
      width: 3px;
      background-color: #ff450b;
    }

    .btn-box {
      display: flex;
      align-items: center;
      .btn {
        line-height: 32px;
        width: 95px;
        border-radius: 4px;
        background-color: rgba(255, 69, 11, 100);
        text-align: center;
        color: #fff;
        margin-left: 15px;
        cursor: pointer;
        font-size: 14px;
      }
    }
  }

  .form-group{
      padding: 0px 20px;
    .form-item {
      display: flex;
      align-items: center;
      padding: 15px 0;
      .label {
        display: flex;
        justify-content: flex-end;
        color: rgba(100, 100, 100, 100);
        font-size: 14px;
        width: 70px;
        padding-right:10px;
      }
      .input-box {
          display: flex;
          width: calc(100% - 100px);
      }
      .required-label{
            color: rgb(255, 0, 0);
      }
      .action-link{
          font-size: 16px;
          cursor:pointer;
          color: #ff450b;
          padding-right: 20px;
      }
    }
  }
  .form-control{
      width: 100%;
  }

  .table-container-body {
    padding: 0 20px;
    .table-body{
      width: 100%;
      overflow: auto;
      border: 1px solid #e6e6e6;
    }
    .logo{
      height: 48px;
      width: 48px;
    }
    .label{
      display: inline-block;
      border: 1px solid #646464;
      font-size: 12px;
      margin-right: 2px;
      border-radius: 4px;
      padding: 0 5px;
      line-height: 14px;
    }
    .table-btn-box {
      display: flex;
      align-items: center;
      justify-content: space-around;
      .table-btn {
        cursor: pointer;
        width: 60px;
        line-height: 28px;
        border-radius: 14px;
        color: rgba(121, 160, 241, 100);
        font-size: 12px;
        text-align: center;
        border: 1px solid rgba(121, 160, 241, 100);
      }
    }
  }
  .table-container-footer{
    padding: 20px;
  }
  
  .school-dialog-body{
    width: 100%;
    .form-group{
      padding:0 !important;
      .form-item{
        padding:0 0 15px 0 !important;
        height: 55px;
      }
    }
    .search-btn {
        line-height: 32px;
        width: 100%;
        border-radius: 4px;
        background-color: rgba(255, 69, 11, 100);
        text-align: center;
        color: #fff;
        cursor: pointer;
        font-size: 14px;
    }
    .table-container-body{
      padding:0 !important;
    }
    .table-container-footer{
      padding: 10px 0;
      border:solid #e6e6e6;
      border-width: 0 1px 1px 1px;
    }
  }
  .dialog-footer{
    display: flex;
    justify-content: flex-end;
    .confirm-btn{
      line-height: 32px;
      width: 60px;
      border-radius: 4px;
      background-color: rgba(255, 69, 11, 100);
      text-align: center;
      color: #fff;
      cursor: pointer;
      font-size: 14px;
    }
  }
}

</style>